import React from "react"
import { getCourseNavigation } from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"

import Box from "../../../../../components/box"
import Column from "../../../../../components/column"
import Columns from "../../../../../components/columns"
import Constrain from "../../../../../components/constrain"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Heading from "../../../../../components/heading"
import Icon from "../../../../../components/icon"
import MarkerHighlight from "../../../../../components/marker-highlight"
import QuotePrimary from "../../../../../components/quote-primary"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import Text from "../../../../../components/text"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "reiseerinnerung" })

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext to="/kurse/reiseerinnerung/02-dichterwerkstatt/alliterationen" />
      }
    >
      <Seo title="Silben klopfen" />
      <Box>
        <Stack>
          <Heading as="h1" level={2}>
            Silben klopfen
          </Heading>
          <Paragraph>
            Die Verse umfassen unterschiedlich viele Silben und sind
            freirhythmisch gestaltet. Ein genauerer Blick auf das metrische
            Versschema zeigt aber, dass gewisse Silben- und Betonungsfolgen
            immer wiederkehren. Insbesondere ein Versmaß, das durch die
            griechische Dichterin Sappho geprägt wurde: Der Adonius – laut
            Winfried Menninghaus eine der eingängigsten metrischen Figuren
            überhaupt.
          </Paragraph>
          <Columns space={1} collapse={[true, false]}>
            <Column>
              <Box sx={{ bg: "muted", p: 4, height: "100%" }}>
                <Stack space={1}>
                  <Text bold sans size={4}>
                    Daktylus
                  </Text>
                  <Text color="whisper" sans>
                    betont, unbetont, unbetont
                  </Text>
                  <Text sans size={6} color="primary" letterSpacing="4px">
                    —◡◡
                  </Text>
                </Stack>
              </Box>
            </Column>
            <Column width="content" sx={{ alignSelf: "center" }}>
              <Icon icon="plus"></Icon>
            </Column>
            <Column>
              <Box sx={{ bg: "muted", p: 4, height: "100%" }}>
                <Stack space={1}>
                  <Text bold sans size={4}>
                    Trochäus
                  </Text>
                  <Text color="whisper" sans>
                    betont, unbetont
                  </Text>
                  <Text sans size={6} color="primary" letterSpacing="4px">
                    —◡
                  </Text>
                </Stack>
              </Box>
            </Column>
            <Column width="content" sx={{ alignSelf: "center" }}>
              <Icon icon="equals"></Icon>
            </Column>
            <Column>
              <Box sx={{ bg: "muted", p: 4, height: "100%" }}>
                <Stack space={1}>
                  <Text bold sans size={4}>
                    Adonius
                  </Text>
                  <Text color="whisper" sans>
                    betont, unbetont, unbetont, betont, unbetont
                  </Text>
                  <Text sans size={6} color="primary" letterSpacing="4px">
                    —◡◡ —◡
                  </Text>
                </Stack>
              </Box>
            </Column>
          </Columns>
          <Paragraph>
            Manchmal steht anstelle des Trochäus auch ein Spondeus, ein Versmaß,
            in dem zwei betonte Silben aufeinanderprallen.
          </Paragraph>
          <Columns space={1} collapse={[true, false]}>
            <Column>
              <Box sx={{ bg: "muted", p: 4, height: "100%" }}>
                <Stack space={1}>
                  <Text bold sans size={4}>
                    Daktylus
                  </Text>
                  <Text color="whisper" sans>
                    betont, unbetont, unbetont
                  </Text>
                  <Text sans size={6} color="primary" letterSpacing="4px">
                    —◡◡
                  </Text>
                </Stack>
              </Box>
            </Column>
            <Column width="content" sx={{ alignSelf: "center" }}>
              <Icon icon="plus"></Icon>
            </Column>
            <Column>
              <Box sx={{ bg: "muted", p: 4, height: "100%" }}>
                <Stack space={1}>
                  <Text bold sans size={4}>
                    Spondeus
                  </Text>
                  <Text color="whisper" sans>
                    betont, betont
                  </Text>
                  <Text sans size={6} color="primary" letterSpacing="4px">
                    ——
                  </Text>
                </Stack>
              </Box>
            </Column>
            <Column width="content" sx={{ alignSelf: "center" }}>
              <Icon icon="equals"></Icon>
            </Column>
            <Column>
              <Box sx={{ bg: "muted", p: 4, height: "100%" }}>
                <Stack space={1}>
                  <Text bold sans size={4}>
                    Adonius
                  </Text>
                  <Text color="whisper" sans>
                    betont, unbetont, unbetont, betont, betont
                  </Text>
                  <Text sans size={6} color="primary" letterSpacing="4px">
                    —◡◡ ——
                  </Text>
                </Stack>
              </Box>
            </Column>
          </Columns>
          <Paragraph>
            Der Adonius hat seinen Namen von den Klagen um den Tod des schönen
            griechischen Jünglings Adonis, der sowohl Frauen als auch Männer
            betört haben soll: »ô ton Adônin« (—◡◡ | —◡).
          </Paragraph>
          <Paragraph>
            Die griechische Dichterin Sappho hat dieses metrische Schema zu
            ihrem Markenzeichen gemacht: Ihre lediglich fragmentarisch
            überlieferten Gedichte, für die sie eine ganz eigene Form der Ode
            kreierte, schließen stets mit dieser Betonungsfolge.
          </Paragraph>
          <Paragraph>
            Hölderlin hat etliche adonische Wortgruppen über den Text vertreut.
            Das rhythmische Betonungsmuster kommt immer wieder vor und wird
            schließlich im letzten Vers noch einmal aufgegriffen, der dadurch zu
            einer Art Höhepunkt wird, auf den die vorausgegangen Verse zulaufen:
          </Paragraph>
          <Constrain align="left">
            <QuotePrimary>
              <p>Und zusammen mit der prächt'gen </p>
              <p>Garonne meerbreit </p>
              <p>Ausgehet der Strom. Es nehmet aber</p>
              <p>Und giebt Gedächtniß die See, </p>
              <p>Und die Lieb’ auch heftet fleißig die Augen, </p>
              <p>
                <MarkerHighlight>
                  Was bleibet aber, stiften die Dichter
                </MarkerHighlight>
                .
              </p>
            </QuotePrimary>
          </Constrain>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
